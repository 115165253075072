import React from 'react';

const Footer = () => {

	return (

		<>

		<footer  className="theme-footer-one footer_bg" id="footer">
			
				<div className="shape-one" data-aos="zoom-in-right"></div>
				<img src="../images/shape/shape-67.svg" alt="" className="shape-two" />
				
					<div className="theme-footer-three" data-aos="fade-up" >
					<div className="container">
						<div className="ftlogo"><a href="#"><img src="../images/logo/ft-logo.png" alt="" /></a></div>
						<p> <a href="mailto:hello@qilinlab.com" className="email">hello@qilinlab.com</a></p>
						<p> <a href="tel:+918050756036" className="ftnum">+91 805-075-6036</a></p>
						<ul className="social-icon">
							<li><a  style={{backgroundColor: '#3b5998', color: 'white'}}  href="https://www.facebook.com/qilinlab" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
							<li><a style={{backgroundColor: '#0e76a8', color: 'white' }} href="https://www.linkedin.com/company/qilinlab/" target="_blank"><i  className="fa fa-linkedin" aria-hidden="true"></i></a></li>
							<li><a style={{backgroundColor: '#db4a39', color: 'white'}} href="https://www.instagram.com/qilinlab/?hl=en" target="_blank"><i className="fa fa-google-plus" aria-hidden="true"></i></a></li>
						</ul>
						<p className="copy-right"> copyright &copy; all right reserved | powered by <a href="#" style={{color: 'white'}}>Qilinlab</a> </p>
					</div>
			{/* <!-- /.theme-footer-three --> */}
			</div>
			
	</footer>
			
			<button className="scroll-top tran3s">
				<i className="fa fa-angle-up" aria-hidden="true"></i>
			</button>
			
		</>

	)

};

			


export default Footer;