import React, {useState, useContext} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from 'react-router-dom';
import { Loader } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'; 
import {SubscriptionDataContext} from '../context/DataContext';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';





function Button({ isLoading, children, ...props }) {
    return (
      <button className="button" {...props}>
        {isLoading ? <Loader /> : children}
      </button>
    );
  }

  const useStyles = makeStyles((theme) => ({
    width: {
      
        width: '100%',
        marginBottom: '20px',
      
    },
    
  }));

const Form = () => {
  // const environment = Config.ENV;
  // const baseUrl = Config.BASE_URL;


  const classes = useStyles();

   const subscriptionAction = useContext(SubscriptionDataContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  
    const notify = () => toast();
    const [isButtonLoading, setIsButtonLoading] = React.useState(false);

  
    const url = "/api/subscribe/start_trial/"
    
    
    const [userRegistration, setUserRegistration] = useState({
        gst: "",
        tallyserial: "",
        phone: "",
        email: ""
    });

  
    const handleInput = (e) => {

        const name = e.target.name;
        
        const value = e.target.value;
       
        
        setUserRegistration({ ...userRegistration, [name]: value })
      
        
    }

    const [records, setRecords] = useState([]);

    const handleSubmit =  (e) => {
      e.preventDefault();
           

        const newRecord = { ...userRegistration}
        setRecords([ ...records, newRecord]);

        // setUserRegistration({gst:"", tallyserial:"", phone:"", email:""})
       
        setLoading(true);
        
         axios.post(url, {
       gst_number: userRegistration.gst,
       tally_sl_no: userRegistration.tallyserial,
        phone: userRegistration.phone,
        email: userRegistration.email 
        })
        
       .then(function (response) {
        setLoading(false);
        if(response.status === 200){

          subscriptionAction.setData(response.data)
      
          history.push("/Thankyou");
          console.log(response);
        }
       
        
    })
        .catch(function (error) {
            setLoading(false);
            console.log(error.response);
            console.log(error.request);
            console.log(error);
           toast.error(error.response.data.msg)            
         });
              

          
    }

    
    
    return (

      <>
      {(loading)? 
        <Loader backdrop content="loading..." vertical />
       :null}
    <div className="forms-style formbgs">
    <h5 className="form_title">Fill The Form & Start Free Trial For 30 Days</h5>
   
          
            <form onSubmit={(e)=>handleSubmit(e)}>
           

                <TextField name="gst" id="gst"  className={classes.width} 
                placeholder="AQRTPBHPC88DFGH"
                required={true}
                InputLabelProps={{ shrink: true }}
                value={userRegistration.gst} required 
                onChange={handleInput} 
                label="Gst Number" 
                inputProps={{
                maxLength: 15,
                }}
                />

                <TextField
                className={classes.width}
                InputLabelProps={{ shrink: true }}
                required={true}
                placeholder="7845120"
                name="tallyserial" 
                id="tallyserial" 
                value={userRegistration.tallyserial}
                onChange={handleInput}
                label="Tally Serial Number"
                type="number"
                inputProps={{
                maxLength: 10,
                }}


                />

                <TextField
                className={classes.width}
                InputLabelProps={{ shrink: true }}
                placeholder="9876543210"
                name="phone"
                id="phone"
                required={true}
                value={userRegistration.phone}
                onChange={handleInput}
                label="Phone Number"
                type="number"
                />

                <TextField
                className={classes.width}
                InputLabelProps={{ shrink: true }}
                placeholder="example@example.com"
                name="email"
                id="email" 
                value={userRegistration.email}
                onChange={handleInput}
                label="Email"
                type="email"
                />
          

                {/* <input type="text" maxLength="15" autoComplete="off" name="gst" id="gst"
                 placeholder="Your GST Number" value={userRegistration.gst} required onChange={handleInput} /> */}
                 

                {/* <input type="number" maxLength="10"   name="tallyserial" id="tallyserial" 
                autoComplete="off" required  value={userRegistration.tallyserial}
                onChange={handleInput} placeholder="Tally Serial Number *" /> */}

                {/* <input type="number"  name="phone" maxLength="10" id="phone" autoComplete="off" required   value={userRegistration.phone}
                 onChange={handleInput} placeholder="Phone Number *" /> */}

              {/* <input type="email" name="email" id="email"  autoComplete="off" value={userRegistration.email}
                    onChange={handleInput} placeholder="Email"
                />  */}
                

                  <Button className="theme-button-two"
                  onClick={() => {
                  setIsButtonLoading(true);
                  setTimeout(() => {
                  setIsButtonLoading(false);
                  }, 2000);
                  }}
                  isLoading={isButtonLoading}
                  >Submit</Button>

                <ToastContainer />
            </form>
           
        </div>

</>
    )
};

export default Form; 