import React from 'react';
import Form from './Form';



  

const Home = () => {

	


	return (
		<>
			<div id="theme-banner-four" >
				
				<img src="images/shape/shape-8.svg" alt="" className="shape-one" />
				<img src="images/shape/shape-9.svg" alt="" className="shape-two" />
				<img src="images/shape/shape-10.svg" alt="" className="shape-three" />
				<img src="images/shape/shape-11.svg" alt="" className="shape-four" />
				<div className="round-shape-one"></div>
				<div className="round-shape-two"></div>
				<div className="round-shape-three"></div>
				<div className="round-shape-four"></div>
				<div className="container"  id="demoform">
				<div className="main-wrapper pb-0">
				<div className="row">
				<div className="col-xl-7">
					<h1 className="main-title wow fadeInUp animated" data-wow-delay="0.4s">
				   Auto fill ledger details
						
						 <br /> Using <span className="gst">GST</span> Number
						</h1>

						<h4 >&#8377;<span style={{textDecoration: 'line-through', fontFamily: '-webkit-body'}}>1800</span>/Yr </h4>

						<h3 className="free">Free Trial For 30 Days</h3>
						
						<ul className="button-group lightgallery">
							<li><a href="#demoform" className="d-none d-lg-block more-button wow fadeInLeft animated" data-wow-delay="1.5s">Start Free Trial <i className="fa fa-angle-right" aria-hidden="true"></i></a></li>
							
							
						</ul>
				</div>
					
				<div className="col-xl-5">
				<Form />
				</div>
				</div>

					</div>
					 {/* <!-- /.main-wrapper --> */}
				</div>
				 
			</div> 
			{/* <!-- /close --> */}

			<div className="our-feature-sass pt-5 mt-5" id="features">

<div className="section-shape-one"></div>

<div className="section-shape-two"><img src="images/shape/shape-18.svg" alt="" /></div>
<img src="images/shape/shape-18.svg" alt="" className="section-shape-three" />
<div className="section-shape-four"></div>
<img src="images/shape/shape-20.svg" alt="" className="section-shape-five" />
<img src="images/shape/shape-21.svg" alt="" className="section-shape-six" />
<img src="images/shape/shape-22.svg" alt="" className="section-shape-seven" />
<img src="images/shape/shape-19.svg" alt="" className="section-shape-eight" />
<div className="feature-wrapper" id="feature-sass">
	<div className="single-feature-block pb-5">
		<div className="container clearfix">

			<div className="text-box">
				<div className="theme-title-one hide-pr">
					<div className="icon-box hide-pr">
						<img src="images/shape/bg-shape2.svg" alt="" className="bg-shape" />
						<img src="images/icon/icon24.svg" alt="" className="icon" />
					</div>
					<h2 className="main-title">Tired of entering customer information again and again.?<b className="line"></b></h2>
				</div>
				
				<p >Our Autofill Customer Details System will fill all customer details like Name, Address,
				 Dealer type just by entering the GST number. <br /> which results in following benefits.</p>
				<ul>
					<li>Impossible input of wrong GST number</li>
					<li>No spelling mistake in customer name and address</li>
					<li>Convenient and Time saving</li>
				
				</ul>
				<div>
					
		
				</div>
				<a href="#demoform" className="explore-button">Start Free Trial</a>
			</div> 
			{/* <!-- /.text-box --> */}
			<div className="img-box">
			
			<img src="images/home/screen5.png" alt="" className="screen-five" data-aos="fade-down" data-aos-delay="800" />

			<img src="images/home/screen5-1.png" alt="" className="screen-fiveone" data-aos="fade-down" data-aos-delay="200" />
		
		</div>

		</div> 
		

		
	</div> 
	



	<div className="single-feature-block pb-5 mb-5">
		<div className="container clearfix">
		<div className="img-box">
			
		
			<img src="images/home/screen1.png" alt="" className="screen-one" data-aos="fade-down" data-aos-delay="600" />
			
		</div>
			<div className="text-box">
				<div className="theme-title-one hide-pr">
					<div className="icon-box hide-pr">
						<img src="images/shape/bg-shape3.svg" alt="" className="bg-shape" />
						<img src="images/icon/icon25.svg" alt="" className="icon" />
					</div>
					<h2 className="main-title">Wrong GST Number<b className="line"></b></h2>
				</div> 
				{/* <!-- /.theme-title-one --> */}
				<p>No need to manual entry of customer or vendor records. Our software will help you to 
				an automatic entry recorded with each new data line .</p>
				
			
			
	
		
			</div> 
			{/* <!-- /.text-box --> */}
			<a href="#demoform" className="explore-button">Start Free Trial</a>
		</div>
		 

		
	</div>
	 

	<div className="single-feature-block pb-5">
		<div className="container clearfix">
			<div className="text-box">
				<div className="theme-title-one hide-pr">
					<div className="icon-box hide-pr">
						<img src="images/shape/bg-shape3.svg" alt="" className="bg-shape" />
						<img src="images/icon/icon25.svg" alt="" className="icon" />
					</div>
					<h2 className="main-title">Long data Entry Time</h2>
				</div>
				
				<p>No need to manual entry of customer or vendor records. Our software will help you to 
				an automatic entry recorded with each new data line .</p>
				
			

				<a href="#demoform" className="explore-button">Start Free Trial</a>
			</div>
			
			
		</div>
		 

		<div className="img-box">
			
			<img src="images/home/screentime.png" alt="" className="screentime" data-aos="fade-down" data-aos-delay="400" />

			<img src="images/home/screentime2.png" alt="" className="screentimetwo" data-aos="fade-down" data-aos-delay="900" />
			
		</div>
	</div> 
	

</div> 

</div> 

			

			
			<div className="our-service-sass hide-pr">
				<div className="container">

				<div className="inner-wrapper">
						<div className="row">
							<div className="col-lg-4 single-block d-flex align-items-stretch" data-aos="fade-up">
								<div className="service-block">
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<div className="hover-content"></div>
									<i className="flaticon-web icon-s"></i>
									<h5 className="title"><a href="index.php#">Automating tasks</a></h5>
									<p align="justify">Manual data entry is tedious and prone to errors.  
									Our Customer Details System requires you 
									to enter GST number only once and will
									 automatically fill all ledger data .</p>
									
								</div>
								
							</div>
							 
							<div className="col-lg-4 single-block d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
								<div className="service-block">
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<div className="hover-content"></div>
									<i className="flaticon-value icon-s"></i>
									<h5 className="title"><a href="index.php#">Easy Integration</a></h5>
									<p align="justify">
									 Our software system is easily integrate to your Tally software and allow
									   access  work with your data and saving your time.
									 </p>
									
								</div>
								
							</div> 
							
							<div className="col-lg-4 single-block d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
								<div className="service-block">
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<span className="snow-dot"></span>
									<div className="hover-content"></div>
									<i className="flaticon-setup icon-s"></i>
									<h5 className="title"><a href="index.php#"> Scalable</a></h5>
									<p align="justify">Reach new heights with the  Customer Details System software that will help your business grow quickly. </p>
									
								</div>
								
							</div> 
							
						</div> 
						{/* <!-- /.row --> */}
					</div> 
					{/* <!-- /.inner-wrap close --> */}


					{/* <div className="theme-title-one text-center mt-5">
						<div className="icon-box hide-pr">
							<img src="images/shape/bg-shape1.svg" alt="" className="bg-shape" />
							<img src="images/icon/icon23.svg" alt="" className="icon" />
						</div>
						<h2 className="main-title">Power your finance, <br />  Grow Your Business.</h2>
						<p >Whether you are starting a business, have a small business or looking to 
						streamline your accounting process in tally. Our software only fill your GST number and our software managing your all customer and vendors data records fill automatic no 
						need to manual entry  of long customer and vendors data We are your one-stop solution for an accounting software.</p>
					</div> */}
					

					
					
				</div>
				 
			</div> 
			

			<h2 className="main-title pb-3" align="center">See Our Demo</h2>
			<div className="col-lg-6 offset-md-3">
            <div className="container">
                <div class="video-action-banner-one mb-5">
                    <div class="overlay">
                        <a data-fancybox href="#" class="video-button fancybox"><img src="images/icon/icon47.svg" alt="" /></a>
                    </div>
                </div> 
            </div>
        </div>

		
			

		
			<section className="bgsfeatures">
			<div className="our-feature-app">
				<div className="container">
				<h4 className="upset">Why Should You </h4>
				<h2 className="solution">Choose Us</h2>
					<div className="row single-feature-box">
						<div className="col-lg-5 order-lg-last">
							<div className="text-wrapper">
								<img src="images/icon/icon30.svg" alt="" className="icon" />
								<h2 className="title">Autofill All Details in Tally Using GST Number.</h2>
								
								<p>Enter GST number in your tally software the data will be autofill to all the required fields.</p>
								<a href="#demoform" className="explore-button">Start Free Trial</a>
							</div>
							 {/* <!-- /.text-wrapper --> */}
						</div> 
						{/* <!-- /.col- --> */}

						<div className="col-lg-7 order-lg-first">
							<div className="feature-img-box">
								<img src="images/shape/shape-45.svg" alt="" className="shape-one" />
								<img src="images/shape/shape-46.svg" alt="" className="shape-two" />
								<img src="images/shape/shape-47.svg" alt="" className="shape-three" />
								<img src="images/shape/shape-48.svg" alt="" className="shape-four" />
								<img src="images/shape/shape-49.svg" alt="" className="shape-five" />
								<div className="row">
									<div className="col-lg-6">
										<div className="feature-offer-box support-feature js-tilt">
											<div className="icon-box"><img src="images/icon/subscribe.png" alt="" /></div>
											<h4 className="title">Free Trial</h4>
											<p>Get a free trial and you’ll have access to all premium features in your tally software</p>
										</div>
										 {/* <!-- /.feature-offer-box --> */}
									</div>
									 {/* <!-- /.col- --> */}
									<div className="col-lg-6">
										<div className="feature-offer-box price-feature js-tilt">
											<div className="icon-box"><img src="images/icon/key.png" alt="" /></div>
											<h4 className="title">Quick Access</h4>
											<p>Hassle free quick access and easy to use.</p>
										</div> 
										{/* <!-- /.feature-offer-box --> */}
										<div className="feature-offer-box access-feature js-tilt">
											<div className="icon-box"><img src="images/icon/chat.png" alt="" /></div>
											<h4 className="title">24h Support</h4>
											<p>Strong support team to support your network 24/7 365 days proactively</p>
										</div> 
										{/* <!-- /.feature-offer-box --> */}
									</div>
									 {/* <!-- /.col- --> */}
								</div>
								 {/* <!-- /.row --> */}
							</div> 
							{/* <!-- /.feature-img-box --> */}
						</div> 
						{/* <!-- /.col- --> */}
					</div> 
					{/* <!-- /.row --> */}

					<div className="row single-feature-box">
						<div className="col-lg-5">
							<div className="text-wrapper">
								<img src="images/icon/icon33.svg" alt="" className="icon" />
								<h2 className="title">Free Trial For 30 Days</h2>
								<p> Try our free 30 day trial offer. The simplest and most intuitive powerful tool for your tally software Submit the above form and we will create 30 days free trial for you. </p>
								<a href="#demoform" className="explore-button">Start Free Trial</a>
							</div> 
							{/* <!-- /.text-wrapper --> */}
						</div> 
						{/* <!-- /.col- --> */}

						<div className="col-lg-7">
							<div className="feature-img-box">
								<img src="images/shape/shape-45.svg" alt="" className="shape-one" />
								<img src="images/shape/shape-46.svg" alt="" className="shape-two" />
								<img src="images/shape/shape-47.svg" alt="" className="shape-three" />
								<img src="images/shape/shape-48.svg" alt="" className="shape-four" />
								<img src="images/shape/shape-49.svg" alt="" className="shape-five" />
								<div className="access-screen-holder">
									<div className="clearfix js-tilt">
										<img src="images/home/screen10.png" alt="" className="screen" data-aos="fade-up" data-aos-duration="1200" />
									</div>
								</div> 
								{/* <!-- /.access-screen-holder --> */}
							</div>
							 {/* <!-- /.feature-img-box --> */}
						</div> 
						{/* <!-- /.col- --> */}
					</div>
					 {/* <!-- /.row --> */}

					
				</div> 
				
			</div> 
			{/* <!-- /.our-feature-app --> */}
			</section> 
			
	


			{/* <!--
			=====================================================
				Testimonial
			=====================================================
			--> */}
			<div className="apps-testimonial bg-white">
				<div className="container">
					<div className="theme-title-one">
						<h2 className="main-title">What’s Our Customer <br />Saying.</h2>
					
					</div> 
					{/* <!-- /.theme-title-one --> */}

					<div className="customer-slider">
					
						<div className="item">
							<div className="customer-block">
								<p>This Software has reduced the cost of manufacturing & my mental stress towards my business

								 </p>
								<div className="clearfix">
									
									<div className="customer-info">
										<h5 className="name">Ravi Kumar</h5>
										
									</div>
									 {/* <!-- /.customer-info --> */}
								</div>
							</div> 
							{/* <!-- /.customer-block --> */}
						</div>


						<div className="item">
							<div className="customer-block">
								<p>The correct accounting software helps you to properly maintain the books of accounts with accuracy and reduces the burden of the organisation, which Tally does for my business.

								 </p>
								<div className="clearfix">
									
									<div className="customer-info">
										<h5 className="name">Sudhakaran</h5>
										
									</div>
									 {/* <!-- /.customer-info --> */}
								</div>
							</div> 
							{/* <!-- /.customer-block --> */}
						</div>


						<div className="item">
							<div className="customer-block">
								<p>“The Customer Details System software provided us synchronisation and availability of data at the click of a mouse.”

								 </p>
								<div className="clearfix">
									
									<div className="customer-info">
										<h5 className="name">Sathyam </h5>
										
									</div>
									 {/* <!-- /.customer-info --> */}
								</div>
							</div> 
							{/* <!-- /.customer-block --> */}
						</div>

				
						
						
						{/* <!-- /.item --> */}
					</div>
				</div> 
				

			</div> 
			{/* <!-- /.apps-testimonial --> */}
		</>
	)
};

export default Home;


		



