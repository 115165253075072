import React, {useContext} from 'react';
import {SubscriptionDataContext} from '../context/DataContext';
import moment from 'moment';



const Thankyou = () => {
    const subscriptionAction = useContext(SubscriptionDataContext);
const {data} = subscriptionAction?.data
   
   

    return (

<section className="thankyou">

<div className="container">
    <div className="row">

    <div className="col-lg-8 offset-lg-4">
        <img src="images/thanks.jpg" alt="" />
    </div>
    <div className="col-12" style={{textAlign: 'center'}}>

    <h3 >CONGRATS, <span style={{fontStyle:'italic', color:'red',}}> {data?.bussiness_name}</span> !</h3>

<h4 style={{marginTop:'10px'}}>We have Emailed / SMS you the plugin along with instructions. </h4>
<p style={{fontSize: '18px', marginTop:'10px'}}>
Our representative shall also contact you in case you need help. Moreover, you can call <br /> 
<b>80507 56036</b>  between <b>10 AM - 8 PM Mon - Sat</b>   for any assistance.
Your free trial will end on <span style={{color: 'red'}}><b>{moment(data?.end_date).format('LL')}</b></span>    </p>


    {/* <h2>CONGRATS, {data?.bussiness_name} !</h2>

	<p style={{fontSize: '18px', marginTop: '20px'}}>
    Our representative shall also contact you in case you need help. Moreover, you can call <br /> 
    <b>80507 56036</b>  between <b>10 AM - 8 PM Mon - Sat</b>   for any assistance.
Your free trial will end on <span style={{color: 'red'}}><b>{moment(data?.end_date).format('LL')}</b></span>    </p> */}
	<a href="/" style={{fontSize: '18px'}} class="back-button line-button-one mt-4">Close</a>
    </div>
			
    </div>
</div>


<h2 className="main-title pb-3 mt-5" align="center">How To Install</h2>
        <div className="col-lg-6 offset-md-3">
            <div className="container">
                <div class="video-action-banner-one mb-5">
                    <div class="overlay">
                        <a data-fancybox href="#" class="video-button fancybox"><img src="images/icon/icon47.svg" alt="" /></a>
                    </div>
                </div> 
            </div>
        </div>
			
	
</section>

    )
};

export default Thankyou;