
import React, {useState} from 'react';



const Header= (props) => {
    

	return (

	
	
		<div className="main-page-wrapper" >

		
			{/* <!-- Preloader --> */}
			<section>
				<div id="preloader">
					<div id="ctn-preloader" className="ctn-preloader">
						<div className="animation-preloader">
							<div className="spinner"></div>
							<div className="txt-loading">
								<span data-text-preloader="Q" className="letters-loading">
									Q
								</span>

								<span data-text-preloader="I" className="letters-loading">
									I
								</span>


								<span data-text-preloader="L" className="letters-loading">
									L
								</span>

								<span data-text-preloader="I" className="letters-loading">
									I
								</span>
							
								<span data-text-preloader="N" className="letters-loading">
									N
								</span>

								
								<span data-text-preloader="L" className="letters-loading">
									L
								</span>
								<span data-text-preloader="A" className="letters-loading">
									A
								</span>
								<span data-text-preloader="B" className="letters-loading">
									B
								</span>
								

								
							</div>
						</div>	
					</div>
				</div>
			</section>


			{/* <!-- 
			=============================================
				Nav Menu
			============================================== 
			--> */}
			<div className="theme-main-menu theme-menu-one d-align-item">
				<div className="logo">
				<a href="https://qilinlab.com/" target="_blank"><img src="images/logo/logo.webp" alt="" /></a>
				</div>
			</div> 
			{/* <!-- /.nav menu --> */}

</div>

	)
};


export default Header;	

	
