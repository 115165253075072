import React, {useState} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useHistory} from 'react-router-dom';
import { Loader } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'; 
// import {SubscriptionDataContext} from '../context/DataContext';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';





function Button({ isLoading, children, ...props }) {
    return (
      <button className="button" {...props}>
        {isLoading ? <Loader /> : children}
      </button>
    );
  }

  const useStyles = makeStyles((theme) => ({
    width: {
      
        width: '100%',
        marginBottom: '20px',
      
    },
    
  }));

const Form = () => {
  // const environment = Config.ENV;
  // const baseUrl = Config.BASE_URL;


  const classes = useStyles();

  //  const subscriptionAction = useContext(SubscriptionDataContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  
    const notify = () => toast();
    const [isButtonLoading, setIsButtonLoading] = React.useState(false);

  
    const url = "/api/gst/track_contact/"
    
    
    const [userRegistration, setUserRegistration] = useState({
        name: "",
        phone: "",
        email: ""
    });

  
    const handleInput = (e) => {

        const name = e.target.name;
        
        const value = e.target.value;
       
        
        setUserRegistration({ ...userRegistration, [name]: value })
      
        
    }

    const [records, setRecords] = useState([]);

    const handleSubmit =  (e) => {
      e.preventDefault();
           

        const newRecord = { ...userRegistration}
        setRecords([ ...records, newRecord]);

        setUserRegistration({name:"", phone:"", email:""})
       
        setLoading(true);
        
         axios.post(url, {
        name: userRegistration.name,
        phone: userRegistration.phone,
        email: userRegistration.email 
        })
        
       .then(function (response) {
        setLoading(false);
        if(response.status === 200){
          console.log(response);

          toast.info('Thank You! for showing your interest. we will contact you soon.')            


          // subscriptionAction.setData(response.data)
          // history.push("/Thankyou");

          // console.log(response);
        }
       
        
    })
        .catch(function (error) {
            setLoading(false);
            console.log(error.response);
            console.log(error.request);
            console.log(error);
           toast.error('Something wrong please check.')            
         });
              

          
    }

    
    
    return (

      <>
      {(loading)? 
        <Loader backdrop content="loading..." vertical />
       :null}
    <div className="forms-style formbgs">
    <h5 className="form_title">Fill The Form & Get Free Trial For 30 Days</h5>
   
          
            <form onSubmit={(e)=>handleSubmit(e)}>
           

              <TextField
                className={classes.width}
                InputLabelProps={{ shrink: true }}
                required={true}
                placeholder="Qilin Lab"
                name="name" 
                id="name" 
                value={userRegistration.name}
                onChange={handleInput}
                label="Full Name"
                type="text"
                />
                

                <TextField
                className={classes.width}
                InputLabelProps={{ shrink: true }}
                placeholder="9876543210"
                name="phone"
                id="phone"
                required={true}
                value={userRegistration.phone}
                onChange={handleInput}
                label="Phone Number"
                type="number"
                />

                <TextField
                className={classes.width}
                InputLabelProps={{ shrink: true }}
                placeholder="example@example.com"
                name="email"
                id="email" 
                value={userRegistration.email}
                onChange={handleInput}
                label="Email"
                type="email"
                />
          
              

                  <Button className="theme-button-two"
                  onClick={() => {
                  setIsButtonLoading(true);
                  setTimeout(() => {
                  setIsButtonLoading(false);
                  }, 2000);
                  }}
                  isLoading={isButtonLoading}
                  >Submit</Button>

                <ToastContainer />
            </form>
           
        </div>

</>
    )
};

export default Form; 