import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter as Router,Route} from 'react-router-dom';
import ScrollToTop from './pages/ScrollToTop';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
   <Router>
   <ScrollToTop />
  <Route path="/" component={App} />
  
</Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
