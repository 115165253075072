
import React from 'react';
import Home from './pages/Home';
import Header from './pages/Header';
import Footer from './pages/Footer';
import Thankyou from './pages/Thankyou';
import Contact from './pages/Contact';
import {Switch, Route} from 'react-router-dom';
import {SubscriptionDataProvider} from './context/DataContext';


const App = () => {
  
  
  return (

    <>
     
      <Header />
      <SubscriptionDataProvider>
      <Switch>
          <Route exact path="/"  component={Home} />
          <Route exact path="/contact"  component={Contact} />
          <Route exact path="/Thankyou"  component={Thankyou} />
         </Switch> 
   </SubscriptionDataProvider>
      <Footer />
     
      


      </>

  )

};


export default App;
