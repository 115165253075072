import React, {createContext, useState} from 'react'

const SubscriptionDataContext = createContext();


function SubscriptionDataProvider(props) {
const [data, setData] = useState();

React.useEffect(()=> {
console.log('alerts', data)
}, [data])

    return (
       
        <SubscriptionDataContext.Provider value={{data, setData}}>
            {props.children}
        </SubscriptionDataContext.Provider>
        
    )
}
export {SubscriptionDataProvider, SubscriptionDataContext}
export default SubscriptionDataProvider;
